// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-courses-blogs-blogs-amazon-training-amazon-training-js": () => import("./../../../src/components/courses_blogs/blogs/amazon_training/amazon_training.js" /* webpackChunkName: "component---src-components-courses-blogs-blogs-amazon-training-amazon-training-js" */),
  "component---src-components-courses-blogs-blogs-data-quest-training-data-quest-training-js": () => import("./../../../src/components/courses_blogs/blogs/data_quest_training/data_quest_training.js" /* webpackChunkName: "component---src-components-courses-blogs-blogs-data-quest-training-data-quest-training-js" */),
  "component---src-components-courses-blogs-blogs-leadership-management-training-leadership-management-training-js": () => import("./../../../src/components/courses_blogs/blogs/leadership_management_training/leadership_management_training.js" /* webpackChunkName: "component---src-components-courses-blogs-blogs-leadership-management-training-leadership-management-training-js" */),
  "component---src-components-courses-blogs-blogs-marketing-road-map-training-marketing-road-map-training-js": () => import("./../../../src/components/courses_blogs/blogs/marketing_road_map_training/marketing_road_map_training.js" /* webpackChunkName: "component---src-components-courses-blogs-blogs-marketing-road-map-training-marketing-road-map-training-js" */),
  "component---src-components-courses-blogs-blogs-process-improvement-training-process-improvement-training-js": () => import("./../../../src/components/courses_blogs/blogs/process_improvement_training/process_improvement_training.js" /* webpackChunkName: "component---src-components-courses-blogs-blogs-process-improvement-training-process-improvement-training-js" */),
  "component---src-components-courses-blogs-blogs-project-management-training-project-management-training-js": () => import("./../../../src/components/courses_blogs/blogs/project_management_training/project_management_training.js" /* webpackChunkName: "component---src-components-courses-blogs-blogs-project-management-training-project-management-training-js" */),
  "component---src-components-courses-blogs-blogs-sales-executive-training-sales-executive-training-js": () => import("./../../../src/components/courses_blogs/blogs/sales_executive_training/sales_executive_training.js" /* webpackChunkName: "component---src-components-courses-blogs-blogs-sales-executive-training-sales-executive-training-js" */),
  "component---src-components-our-services-index-js": () => import("./../../../src/components/our_services/index.js" /* webpackChunkName: "component---src-components-our-services-index-js" */),
  "component---src-components-our-services-services-individuals-corporate-management-training-corporate-management-training-js": () => import("./../../../src/components/our_services/services_individuals/corporate_management_training/corporate_management_training.js" /* webpackChunkName: "component---src-components-our-services-services-individuals-corporate-management-training-corporate-management-training-js" */),
  "component---src-components-our-services-services-individuals-learning-development-consultation-learning-development-consultation-js": () => import("./../../../src/components/our_services/services_individuals/learning_development_consultation/learning_development_consultation.js" /* webpackChunkName: "component---src-components-our-services-services-individuals-learning-development-consultation-learning-development-consultation-js" */),
  "component---src-components-our-services-services-individuals-program-standard-based-training-program-standard-based-training-js": () => import("./../../../src/components/our_services/services_individuals/program_standard_based_training/program_standard_based_training.js" /* webpackChunkName: "component---src-components-our-services-services-individuals-program-standard-based-training-program-standard-based-training-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-become-a-trainer-js": () => import("./../../../src/pages/become-a-trainer.js" /* webpackChunkName: "component---src-pages-become-a-trainer-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-trainers-js": () => import("./../../../src/pages/trainers.js" /* webpackChunkName: "component---src-pages-trainers-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

